import type { LottieComponentProps } from 'lottie-react';

import { styled } from '@mui/material/styles';
import Lottie from 'lottie-react';

type OldLottieProps = {
  options: {
    animationData: any;
    loop?: boolean;
    autoplay?: boolean;
    [key: string]: any;
  };
  [key: string]: any;
};

const LottieWrapper = (props: OldLottieProps | LottieComponentProps) => {
  // Check if using old props structure
  if ('options' in props) {
    const { options, ...rest } = props;
    return <Lottie {...options} {...rest} />;
  }

  // New props structure
  return <Lottie {...props} />;
};

export const AppLottie = styled(LottieWrapper)``;
